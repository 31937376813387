import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { DRAWER_HEADER_CLASSNAME } from '@noths/polaris-client-ribbons-design-system';
import { mediaQuery } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

import { DESKTOP_FILTER_HEIGHT_CSS_VAR } from 'src/components/organisms/Filter/constants/cssVariables';

const { spacing, text } = theme;

export const smallScreenWrapper = css`
  display: block;
  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: none;
    `,
  )};
`;

export const filterListWrapper = css`
  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      height: 100vh;
      height: var(${DESKTOP_FILTER_HEIGHT_CSS_VAR});
      overflow-x: hidden;
      overflow-y: auto;
      ${
        /* Stop z-indexed items inside this wrapper overlapping the scrollbar in Chrome on MacOS */ ''
      }
      transform: translateZ(0);
    `,
  )};

  .${DRAWER_HEADER_CLASSNAME} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: ${spacing.xl}px ${spacing.md}px ${spacing.xl}px ${spacing.md}px;

    button {
      margin: 0;
    }
  }
`;

export const list = css`
  margin: 40px ${spacing.xl}px;
  padding: 0;
  border-top: 1px solid var(--neutral-black-30);

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin: 0;
      padding: 0;
    `,
  )};
`;

export const listItem = css`
  padding: 0;
`;

export const filterTitle = css`
  margin: ${spacing.sm}px 0;
  display: flex;
  align-items: center;
  ${fontSize(text.BODY.MD)};
  text-transform: capitalize;
`;

export const border = css`
  border-bottom: 1px solid var(--neutral-black-30);
`;
