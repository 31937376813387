import React, { useRef } from 'react';
import { grid, maxWidthContainer } from '@noths/polaris-client-ribbons-base';
import {
  IconFilter,
  NumericalLozenge,
  TextButton,
} from '@noths/polaris-client-ribbons-design-system';

import * as elementIds from 'src/components/organisms/FilterMenu/constants/elementIds';
import { SortByContainer } from 'src/components/organisms/FilterMenu/containers/SortByContainer';
import { useStickyNav } from 'src/components/organisms/FilterMenu/hooks/useSlimStickyNav/useStickyNav';
import * as styles from 'src/components/organisms/FilterMenu/styles/SlimFilterMenu.styles';
import type { SlimFilterMenuProps } from 'src/components/organisms/FilterMenu/types';
import { getSlimFilterMenuLabels } from './getSlimFilterMenuLabels';

export const SlimFilterMenu = ({
  onResetFilters,
  onVisible,
  openFilterModal,
  pageProductsCount,
  selectedSortOption,
  totalNumberOfActiveOptions,
  totalNumberOfFiltersApplied,
  totalProducts,
}: SlimFilterMenuProps) => {
  const navRef = useRef<HTMLDivElement>(null);
  const stickyNav = useStickyNav(navRef.current, {
    productsCount: pageProductsCount,
    onFirstStickyVisibility: onVisible,
  });

  const {
    accessibleFilterLabel,
    accessibleResetAllLabel,
    filterAndSortLabel,
    filterLabel,
    resetAllLabel,
  } = getSlimFilterMenuLabels(totalNumberOfActiveOptions, selectedSortOption);

  return (
    <>
      <div
        css={styles.smallScreenMenu}
        data-is-sticky={stickyNav.isSticky}
        data-is-visible={stickyNav.isVisible}
        data-testid={elementIds.STICKY_NAV}
        ref={navRef}
      >
        <div css={styles.smallScreenCTAWrapper}>
          <p css={styles.totalProducts}>{totalProducts} products</p>
          <button
            aria-label={accessibleFilterLabel}
            css={styles.filterButton}
            data-testid={elementIds.BUTTON_OPEN_MODAL}
            onClick={() =>
              openFilterModal({ totalNumberOfFiltersApplied, totalNumberOfActiveOptions })
            }
            type="button"
          >
            {totalNumberOfActiveOptions > 0 && (
              <NumericalLozenge
                value={totalNumberOfActiveOptions > 99 ? '99+' : `${totalNumberOfActiveOptions}`}
              />
            )}
            {filterAndSortLabel}
            <IconFilter className="iconFilter" css={styles.iconFilter} />
          </button>
        </div>
      </div>
      <div css={styles.largeScreenMenu}>
        <div css={[maxWidthContainer, grid]}>
          <div css={styles.filterBar}>
            <div css={styles.filterToolsWrapper}>
              <h3 aria-label={accessibleFilterLabel} css={styles.filterLabel}>
                {filterLabel}
              </h3>
              <div css={styles.resetButtonWrapper}>
                <TextButton
                  aria-label={accessibleResetAllLabel}
                  data-testid={elementIds.BUTTON_RESET_FILTERS}
                  onClick={() =>
                    onResetFilters({
                      totalNumberOfFiltersApplied,
                      totalNumberOfActiveOptions,
                    })
                  }
                >
                  <span css={styles.resetButtonText}>{resetAllLabel}</span>
                </TextButton>
              </div>
              <p css={styles.totalProducts}>{totalProducts} products</p>
            </div>
            <div css={styles.sortByWrapper}>
              <SortByContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
