import React from 'react';
import { useImpressionRef } from '@noths/polaris-client-react-hooks';

import { FilterOptionsContainer } from 'src/components/organisms/Filter/containers/FilterOptionsContainer';
import * as styles from 'src/components/organisms/Filter/styles/FilterList.styles';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';

interface Props {
  filter: BrowseDataAPIFilter;
  onFilterListItemVisibility: (filterUid: string) => void;
}

export const FilterListItem = ({ filter, onFilterListItemVisibility }: Props) => {
  const listItemRef = useImpressionRef<HTMLDivElement>(() =>
    onFilterListItemVisibility(filter.uid),
  );

  return (
    <div css={styles.listItem} data-testid={`listitem-${filter.title}`} ref={listItemRef}>
      <FilterOptionsContainer filter={filter} key={filter.uid} />
    </div>
  );
};
