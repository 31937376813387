import { batch, connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import { FilterList } from 'src/components/organisms/Filter/components/FilterList';
import {
  FILTER_CONTROLS_CLOSE,
  FILTER_NO_MATCHING_PRODUCTS,
} from 'src/components/organisms/Filter/constants/copy';
import {
  selectAppliedFilterIDs,
  selectFilters,
  selectFiltersWithoutSortFilter,
  selectTotalActiveOptions,
} from 'src/components/organisms/Filter/modules/selectors/selectors';
import { actions as filterActions } from 'src/components/organisms/Filter/modules/slice';
import { actions as filterMenuActions } from 'src/components/organisms/FilterMenu/modules/slice';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { updateFilters, UpdateFiltersActionType } from 'src/redux/products/thunks/updateFilters';
import type { AppThunkDispatch } from 'src/redux/store';
import type {
  FilterListContainerDispatchProps,
  FilterListContainerStateProps,
} from './FilterListContainer.types';

interface FilterListContainerProps {
  removeSortFilter?: boolean;
}

const getModalCloseButtonText = (products: ReduxApplicationState['products']) => {
  if (products.error) {
    return FILTER_CONTROLS_CLOSE;
  }

  if (products.totalProducts === 0) {
    return FILTER_NO_MATCHING_PRODUCTS;
  }

  return `View ${products.totalProducts} products`;
};

export const mapStateToProps = (
  state: ReduxApplicationState,
  { removeSortFilter }: FilterListContainerProps,
): FilterListContainerStateProps => {
  return {
    error: state.products.error,
    filters: removeSortFilter ? selectFiltersWithoutSortFilter(state) : selectFilters(state),
    isLoading: state.products.isLoading,
    modalCloseButtonText: getModalCloseButtonText(state.products),
    filtersAppliedIDs: selectAppliedFilterIDs(state),
    totalNumberOfActiveOptions: selectTotalActiveOptions(state),
    totalNumberOfFiltersApplied: selectAppliedFilterIDs(state).length,
  };
};

export const mapDispatchToProps = (
  dispatch: AppThunkDispatch,
): FilterListContainerDispatchProps => ({
  onAllFiltersVisible: () => {
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Filters',
      event_action: 'filter impression',
      event_label: 'all filters viewed',
    });
  },
  onCloseFilters: () => dispatch(filterActions.closeModal()),
  onDeliveryFilterVisible: () => {
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Filters',
      event_action: 'filter impression',
      event_label: 'delivery',
    });
  },
  onResetFilters: ({ totalNumberOfActiveOptions, totalNumberOfFiltersApplied }) => {
    batch(() => {
      dispatch(filterActions.resetFilters());
      dispatch(filterMenuActions.resetSelectedSortOption());
      dispatch(updateFilters(UpdateFiltersActionType.FILTER));
    });
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Filters',
      event_action: 'reset all filters',
      event_label: `number of categories: ${totalNumberOfFiltersApplied}|number of filters: ${totalNumberOfActiveOptions}`,
    });
  },
  onViewResults: ({ totalNumberOfActiveOptions, totalNumberOfFiltersApplied }) => {
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Filters',
      event_action: 'view results',
      event_label: `number of categories: ${totalNumberOfFiltersApplied}|number of filters: ${totalNumberOfActiveOptions}`,
    });

    dispatch(filterActions.closeModal());
  },
});

export const FilterListContainer = connect(mapStateToProps, mapDispatchToProps)(FilterList);
