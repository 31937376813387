import React, { useEffect } from 'react';
import { useWindowSize } from 'react-use';
import { theme } from '@noths/polaris-client-ribbons-base';
import { Accordion, DrawerHeader } from '@noths/polaris-client-ribbons-design-system';
import { pxToRem } from '@noths/polaris-client-styles';
import { useRouter } from 'next/router';

import {
  FILTER_LIST_RESET,
  FILTER_MODAL_HEADING,
} from 'src/components/organisms/Filter/constants/copy';
import { DESKTOP_FILTER_HEIGHT_CSS_VAR } from 'src/components/organisms/Filter/constants/cssVariables';
import { FilterUid } from 'src/components/organisms/Filter/constants/uids';
import type { FilterListProps } from 'src/components/organisms/Filter/containers/FilterListContainer.types';
import * as styles from 'src/components/organisms/Filter/styles/FilterList.styles';
import * as modalStyles from 'src/components/organisms/Filter/styles/FilterModal.styles';
import { FilterControls } from './FilterControls';
import { FilterListItem } from './FilterListItem';
import { ListingsApiError } from './ListingsApiError';

const setFilterHeight = (windowHeight: number) =>
  document.documentElement.style.setProperty(
    DESKTOP_FILTER_HEIGHT_CSS_VAR,
    `calc(${windowHeight}px - ${pxToRem(theme.spacing.xl)})`,
  );

export const FilterList = ({
  error,
  filters,
  filtersAppliedIDs,
  isLoading,
  modalCloseButtonText,
  onAllFiltersVisible,
  onCloseFilters,
  onDeliveryFilterVisible,
  onResetFilters,
  onViewResults,
  totalNumberOfActiveOptions,
  totalNumberOfFiltersApplied,
}: FilterListProps) => {
  const router = useRouter();
  const { height } = useWindowSize();

  useEffect(() => {
    setFilterHeight(height);
  }, [height]);

  const handleFilterVisible = (filterUid: string) => {
    const filterIndex = filters.findIndex(({ uid }) => uid === filterUid);
    const lastFilter = filterIndex === filters.length - 1;

    if (filterUid === FilterUid.Delivery) {
      onDeliveryFilterVisible();
    }
    if (lastFilter) {
      onAllFiltersVisible();
    }
  };

  return (
    <form
      css={[modalStyles.modalViewport, styles.filterListWrapper]}
      onSubmit={(e) => e.preventDefault()}
    >
      <div css={styles.smallScreenWrapper}>
        <DrawerHeader headingLevel="h2" onClose={onCloseFilters} title={FILTER_MODAL_HEADING} />
      </div>
      {error ? (
        <ListingsApiError />
      ) : (
        <div css={[styles.list, modalStyles.modalScrollableContent]}>
          {filters.map((filter, index) => {
            if (filter.options.length === 0) {
              return null;
            }
            const open =
              filtersAppliedIDs.includes(filter.uid) ||
              (index === 0 && filtersAppliedIDs.length === 0);
            return (
              <div css={styles.border} key={filter.uid}>
                <Accordion
                  id={filter.uid}
                  open={open}
                  title={<h4 css={styles.filterTitle}>{filter.title}</h4>}
                >
                  <FilterListItem
                    filter={filter}
                    onFilterListItemVisibility={handleFilterVisible}
                  />
                </Accordion>
              </div>
            );
          })}
        </div>
      )}
      <div css={styles.smallScreenWrapper}>
        <FilterControls
          isLoading={isLoading}
          modalCloseButtonText={modalCloseButtonText}
          onReset={() =>
            onResetFilters(
              {
                totalNumberOfFiltersApplied,
                totalNumberOfActiveOptions,
              },
              router,
            )
          }
          onViewResults={() =>
            onViewResults({
              totalNumberOfFiltersApplied,
              totalNumberOfActiveOptions,
            })
          }
          resetText={FILTER_LIST_RESET}
        />
      </div>
    </form>
  );
};
