import { maxBreakpoints, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { BUTTON__CONTENT_CLASS } from '@noths/polaris-client-ribbons-design-system';
import { mediaQuery, shadowDown } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, responsiveText, spacing } = theme;

export const FILTER_MENU_SMALL_TRANSITION_DURATION = 500;

/**
 * display: contents is needed for the sticky filter menu to work.
 */

export const smallScreenMenu = css`
  position: static;
  top: 0;
  z-index: 1;
  transform: translateY(-100%);
  transition: transform ${FILTER_MENU_SMALL_TRANSITION_DURATION / 1000}s;
  background: var(--neutral-white-base);
  ${shadowDown};
  display: contents;
  @supports not (display: contents) {
    display: inline;
  }

  &[data-is-visible='true'] {
    transform: translateY(0);
  }

  &[data-is-sticky='true'] {
    position: sticky;
  }

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: none;
    `,
  )};
`;

export const smallScreenCTAWrapper = css`
  display: flex;
  padding: ${spacing.xs}px 0;
`;

export const filterButton = css`
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--neutral-white-base);
  border-radius: 6px;
  margin-left: auto;
  margin-right: ${spacing.base}px;
  border: none;
  font-family: ${fonts.PRIMARY_SEMIBOLD.family};
  font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
  ${responsiveText.BODY.MD};
  white-space: nowrap;
  color: var(--neutral-black-base);
`;

export const largeScreenMenu = css`
  display: none;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: block;
      padding: 0 0 ${spacing.sm}px 0;
      margin: 0 0 ${spacing.xxxxl}px 0;
      ${shadowDown};
      background: white;
    `,
  )};
`;

export const resetButtonWrapper = css`
  width: 105px;
  padding: ${spacing.base}px ${spacing.xs}px;
`;

export const resetButtonText = css`
  ${responsiveText.BODY.SM};
`;

export const filterToolsWrapper = css`
  display: flex;
  align-items: center;
`;

export const filterLabel = css`
  margin: 0;
  width: 110px;
  ${responsiveText.BODY.MD};
`;

export const totalProducts = css`
  margin: 0;
  ${responsiveText.BODY.SM};

  ${mediaQuery(
    { max: maxBreakpoints.M },
    css`
      flex-grow: 1;
      padding-left: ${spacing.md}px;
      padding-top: ${spacing.base}px;
    `,
  )};
`;

export const iconFilter = css`
  margin-left: ${spacing.xs}px;
  margin-right: ${spacing.xs}px;
  width: ${spacing.md}px;
  height: ${spacing.md}px;
`;

export const filterBar = css`
  display: flex;
  align-items: center;
  grid-column: 1 / span 12;
`;

export const sortByWrapper = css`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;

  .${BUTTON__CONTENT_CLASS} {
    justify-content: flex-end;
  }
`;
