import React from 'react';
import { NotFound } from '@noths/polaris-client-next-components';
import { TextButton } from '@noths/polaris-client-ribbons-design-system';
import { isBrowser } from '@noths/polaris-client-utils';

import { ERROR_HEADING, TRY_AGAIN_CTA } from './copy';
import * as styles from './styles';

interface ErrorGuardProps {
  children: React.ReactNode;
  error: string | null;
  notFoundErrorList?: string[];
  onRetry: () => void;
}

const ServiceError = ({ onRetry }: Pick<ErrorGuardProps, 'onRetry'>) => (
  <>
    {isBrowser() ? (
      <h2 css={styles.heading}>{ERROR_HEADING}</h2>
    ) : (
      <h1 css={styles.heading}>{ERROR_HEADING}</h1>
    )}
    {onRetry && (
      <div>
        <TextButton onClick={onRetry}>{TRY_AGAIN_CTA}</TextButton>
      </div>
    )}
  </>
);

export const ErrorGuard = ({ children, error, notFoundErrorList, onRetry }: ErrorGuardProps) => {
  return error ? (
    <div css={styles.errorGuard}>
      {notFoundErrorList?.includes(error) ? <NotFound /> : <ServiceError onRetry={onRetry} />}
    </div>
  ) : (
    <>{children}</>
  );
};
