import type { ProductCarouselProduct } from '@noths/polaris-client-ribbons-design-system';
import type { CurrencyCode, RecommendedProduct } from '@noths/polaris-dev-ts-types';

export const transformDecoratedRecommendationsServiceProductToProductCarouselProduct = (
  {
    images,
    linkURL: trackingURL,
    links,
    new: isNew,
    pre_sale_prices,
    prices,
    purchasable: isPurchasable,
    sale_percentage,
    title,
  }: RecommendedProduct,
  currency: CurrencyCode,
): ProductCarouselProduct => {
  const price = prices?.find((prices) => prices.currency === currency);
  const preSalePrice = pre_sale_prices?.find((price) => price.currency === currency);

  return {
    title,
    src: images[0]?.href || '',
    href: links[0]?.href || '',
    alt: '',
    isNew,
    isPurchasable,
    price: price ? price.amount : 0,
    preSalePrice: preSalePrice ? preSalePrice.amount : undefined,
    salePercentage: sale_percentage ?? undefined,
    trackingURL,
  };
};
