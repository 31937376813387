import { batch, connect } from 'react-redux';
import { sendGAEvent } from '@noths/polaris-client-google-analytics';

import {
  selectAppliedFilterIDs,
  selectTotalActiveOptions,
} from 'src/components/organisms/Filter/modules/selectors/selectors';
import { actions as filterActions } from 'src/components/organisms/Filter/modules/slice';
import { SlimFilterMenu } from 'src/components/organisms/FilterMenu/components/SlimFilterMenu/SlimFilterMenu';
import { selectSelectedSortingOptionInSentenceCase } from 'src/components/organisms/FilterMenu/modules/selectors';
import { actions as filterMenuActions } from 'src/components/organisms/FilterMenu/modules/slice';
import type {
  SlimFilterMenuContainerDispatchProps,
  SlimFilterMenuContainerStateProps,
} from 'src/components/organisms/FilterMenu/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { updateFilters, UpdateFiltersActionType } from 'src/redux/products/thunks/updateFilters';
import type { AppThunkDispatch } from 'src/redux/store';

const mapDispatchToProps = (dispatch: AppThunkDispatch): SlimFilterMenuContainerDispatchProps => ({
  onVisible: () => {
    sendGAEvent({
      event: 'custom_event',
      event_action: 'filter & sort sticky impression',
      event_category: 'Filter & sort',
      event_label: 'icon',
    });
  },
  openFilterModal: ({ totalNumberOfActiveOptions, totalNumberOfFiltersApplied }) => {
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Filters',
      event_action: 'open modal',
      event_label: `number of categories: ${totalNumberOfFiltersApplied}|number of filters: ${totalNumberOfActiveOptions}`,
    });
    dispatch(filterActions.openModal());
  },
  onResetFilters: ({ totalNumberOfActiveOptions, totalNumberOfFiltersApplied }) => {
    batch(() => {
      dispatch(filterActions.resetFilters());
      dispatch(filterMenuActions.resetSelectedSortOption());
      dispatch(updateFilters(UpdateFiltersActionType.FILTER));
    });
    sendGAEvent({
      event: 'custom_event',
      event_category: 'Filters',
      event_action: 'reset all filters',
      event_label: `number of categories: ${totalNumberOfFiltersApplied}|number of filters: ${totalNumberOfActiveOptions}`,
    });
  },
});

const mapStateToProps = (state: ReduxApplicationState): SlimFilterMenuContainerStateProps => ({
  totalNumberOfFiltersApplied: selectAppliedFilterIDs(state).length,
  totalNumberOfActiveOptions: selectTotalActiveOptions(state),
  totalProducts: state.products.totalProducts,
  selectedSortOption: selectSelectedSortingOptionInSentenceCase(state),
  pageProductsCount: state.products.productsReturnedCount,
});

export const SlimFilterMenuContainer = connect(mapStateToProps, mapDispatchToProps)(SlimFilterMenu);
