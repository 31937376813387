import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { DRAWER_HEIGHT_CSS_VAR } from '@noths/polaris-client-ribbons-design-system';
import { inset, mediaQuery, shadowDown, shadowUp } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { fonts, spacing, text } = theme;

export const modalViewport = css`
  display: flex;
  flex-direction: column;
  height: 100vh;
  height: var(${DRAWER_HEIGHT_CSS_VAR});
  min-height: 100%;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      height: auto;
    `,
  )};
`;

export const modalScrollableContent = css`
  flex-shrink: 1;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      overflow: visible;
    `,
  )};
`;

export const modalSectionHeader = css`
  padding: ${inset(spacing.md, spacing.lg)};
  ${shadowDown}

  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  h2,
  h3 {
    font-family: ${fonts.PRIMARY_SEMIBOLD.family};
    font-weight: ${fonts.PRIMARY_SEMIBOLD.weight};
    ${fontSize(text.HEADING.SM)}
    line-height: 28px;

    margin: 0;
    text-transform: capitalize;
  }
`;

export const modalSectionControls = css`
  padding: ${inset(spacing.md, spacing.lg)};
  ${shadowUp}
`;
