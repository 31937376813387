import React from 'react';
import { grid, maxWidthContainer } from '@noths/polaris-client-ribbons-base';
import { ProductCarousel } from '@noths/polaris-client-ribbons-design-system';
import { linkClickOpensInNewTab } from '@noths/polaris-client-utils';

import { PreventSwipeNavigation } from 'src/components/molecules/PreventSwipeNavigation/PreventSwipeNavigation';
import type { RecommendedProductsCarouselProps } from 'src/components/organisms/RecommendedProductsShelf/types';
import { transformDecoratedRecommendationsServiceProductToProductCarouselProduct } from 'src/components/organisms/RecommendedProductsShelf/utils/transformDecoratedRecommendationsServiceProductToProductCarouselProduct';
import * as styles from './RecommendedProductsCarousel.styles';

export const RecommendedProductsCarousel = ({
  currencyCode,
  onNavigation,
  onProductClick,
  onVisible,
  placementData,
  placementType,
  ...restOfProps
}: RecommendedProductsCarouselProps) => {
  const { placementStrategy = '', placementTitle, products } = placementData || {};
  const visible = products === null || products.length > 0;

  return visible ? (
    <PreventSwipeNavigation css={[grid, maxWidthContainer]}>
      <div css={styles.recommendedProductsShelfOuter}>
        <div css={styles.recommendedProductsShelfInner}>
          <ProductCarousel
            currency={currencyCode}
            lazyImages
            onNavigation={(swiped, control) => {
              onNavigation({
                navigationType: swiped ? 'Swipe' : 'Arrow click',
                navigationDirection: control === 'next' ? 'next' : 'previous',
                placementStrategy,
                placementType,
              });
            }}
            onProductClick={(evt, { index }) => {
              const linkOpensInSameWindow = !linkClickOpensInNewTab(evt);

              if (linkOpensInSameWindow) {
                evt.preventDefault();
              }

              const product = products[index];

              onProductClick({
                product,
                productIndex: index,
                placementStrategy,
                placementType,
              }).then(() => {
                if (linkOpensInSameWindow) {
                  window.location.assign(product.links[0]?.href || '');
                }
              });
            }}
            onVisible={() => {
              onVisible({ products, placementStrategy });
            }}
            products={
              products
                ? products.map((data) =>
                    transformDecoratedRecommendationsServiceProductToProductCarouselProduct(
                      data,
                      currencyCode,
                    ),
                  )
                : null
            }
            title={placementTitle}
            {...restOfProps}
          />
        </div>
      </div>
    </PreventSwipeNavigation>
  ) : null;
};
