import { connect } from 'react-redux';
import type { Dispatch } from 'redux';

import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { refetchProducts } from 'src/redux/products/thunks/refetchProducts';
import {
  CATEGORY_NOT_FOUND_ERROR,
  PARTNER_NOT_FOUND_ERROR,
} from 'src/redux/products/thunks/thunkErrors';
import type { AppThunkDispatch } from 'src/redux/store';
import { ErrorGuard } from './ErrorGuard';

export const mapStateToProps = ({ content, filter, products }: ReduxApplicationState) => {
  let error = null;

  if (!!products.error && products.error.name && !filter.filterModal.isModalOpen) {
    error = products.error.name;
  } else if (content.error) {
    error = content.error;
  }

  return {
    error,
    notFoundErrorList: [
      CATEGORY_NOT_FOUND_ERROR.name,
      PARTNER_NOT_FOUND_ERROR.name,
      'NO_CONTENT_FOR_CATEGORY_PAGE',
    ],
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  onRetry: () => (dispatch as AppThunkDispatch)(refetchProducts()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

/**
 * @deprecated refactor to avoid using this
 */
export const ListingsApiErrorGuardContainer = connector(ErrorGuard);
