import React, { useEffect, useState } from 'react';
import { grid } from '@noths/polaris-client-ribbons-base';
import type { ProductCardProps } from '@noths/polaris-client-ribbons-design-system';
import { ProductCard } from '@noths/polaris-client-ribbons-design-system';
import {
  addParamsToHref,
  getDeliveryByEventLabel,
  getProductIdFromProductCode,
  isMiddleMouseClick,
  linkClickOpensInNewTab,
} from '@noths/polaris-client-utils';

import type { ProductListProps } from 'src/components/organisms/ProductList/types';
import { PRODUCT_CARD_GRID_ITEM_ID } from 'src/components/organisms/ProductPages/constants/elementIds';
import type { BrowseDataAPIProduct } from 'src/services/browse-data-api/types/BrowseDataAPIProduct';
import { PRODUCT_ID_DATA_ATTRIBUTE } from 'src/utils/scrollProductCardIntoView';
import * as styles from './ProductList.styles';

const INITIAL_NUMBER_OF_PRODUCTS_TO_DISABLE_LAZY_LOADING = 6;
export const PRODUCT_CARD_GRID_ITEM_TEST_ID = 'PRODUCT_CARD_GRID_ITEM_TEST_ID';

const getProductCardPrimaryLabel = (
  isNew: boolean,
  purchasable: boolean,
  salePercentage: number,
): { text: string; type: ProductCardProps['primaryLabelType'] } | null => {
  if (!purchasable) {
    return {
      text: 'Out of stock',
      type: 'stock',
    };
  }
  if (salePercentage > 0) {
    return {
      text: `${salePercentage}% off`,
      type: 'offer',
    };
  }
  if (isNew) {
    return {
      text: 'New',
      type: 'new',
    };
  }

  return null;
};

/**
 * Currently responsible for rendering:
 *  - lists of products
 *  - lists of expandedProducts (if expanded search scenario)
 *  - expanded search messaging
 *
 * Should be responsible for rendering:
 *  - a list of products (agnostic about whether it's a full page, exact results, expanded search results)
 * @component
 */
export const ProductList = ({
  currency,
  expandedProducts,
  expandedResultsMessage,
  favouriteProductCodes = [],
  onProductClick,
  onProductVisible,
  page,
  products,
  providerAttributionToken,
  referredBy,
  updateFavourite,
  updatePageHistory,
}: ProductListProps) => {
  const [isClient, setIsClient] = useState(false);
  const [fadeInProductList] = useState(isClient);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const handleProductVisible: ProductCardProps['onVisible'] = ({ productCode }) => {
    onProductVisible({ productCode });
  };

  const handleProductCardClick =
    (mousedown: boolean) =>
    (evt: React.MouseEvent<HTMLAnchorElement>, { productCode }: { productCode: number }) => {
      const opensInNewTab = linkClickOpensInNewTab(evt) || isMiddleMouseClick(evt);

      if (mousedown && !isMiddleMouseClick(evt)) {
        return;
      }

      if (!opensInNewTab) {
        evt.preventDefault();
      } else {
        updatePageHistory({ page, productCode });
      }

      const hrefWithTrackingParams = addParamsToHref(evt.currentTarget.href, {
        ...(providerAttributionToken && { attributionToken: providerAttributionToken }),
        ...(referredBy && { referredBy }),
      });

      onProductClick({
        productUrl: hrefWithTrackingParams,
        destinationUrl: evt.currentTarget.href,
        productCode,
        linkOpensInSameWindow: !opensInNewTab,
      });
    };

  const renderProducts = (products: BrowseDataAPIProduct[]) => {
    return products.map((product, index) => {
      const deliveryEventMessage = product.deliveryByEvent?.arriveByEvent
        ? getDeliveryByEventLabel(product.deliveryByEvent.eventName)
        : undefined;
      const imageLoading =
        index < INITIAL_NUMBER_OF_PRODUCTS_TO_DISABLE_LAZY_LOADING ? 'eager' : 'lazy';
      const primaryLabel = getProductCardPrimaryLabel(
        product.isNew,
        product.purchasable,
        product.salePercentage || 0,
      );

      return (
        <div
          css={styles.productCardGridItem}
          data-testid={PRODUCT_CARD_GRID_ITEM_TEST_ID}
          id={`${PRODUCT_CARD_GRID_ITEM_ID}-${index}`}
          key={`${product.code}`}
          {...{ [PRODUCT_ID_DATA_ATTRIBUTE]: getProductIdFromProductCode(product.code) }}
        >
          <ProductCard
            code={product.code}
            currency={currency}
            deliveryEventMessage={deliveryEventMessage}
            deliveryLabelText={product.freeDomesticDelivery ? 'Free delivery' : undefined}
            favourite={favouriteProductCodes.includes(String(product.code))}
            flushEdge={index % 2 === 0 ? 'left' : 'right'}
            href={product.links[0]?.rel || ''}
            imageLoading={imageLoading}
            imageSrc={product.images.length > 0 ? product.images[0].href : ''}
            onFavouriteClick={updateFavourite}
            onProductClick={handleProductCardClick(false)}
            onProductMouseDown={handleProductCardClick(true)}
            onVisible={handleProductVisible}
            partnerName={product.partner.name}
            preSalePrice={
              product.preSalePrices && product.preSalePrices[0]
                ? product.preSalePrices[0].amount
                : undefined
            }
            price={product.price.amount}
            title={product.title}
            {...(primaryLabel
              ? {
                  primaryLabelText: primaryLabel.text,
                  primaryLabelType: primaryLabel.type,
                }
              : {})}
          />
        </div>
      );
    });
  };

  return (
    <div css={[grid, styles.productListWrapper, fadeInProductList && styles.fadeIn]}>
      {products && renderProducts(products)}
      {expandedResultsMessage && expandedResultsMessage}
      {expandedProducts && renderProducts(expandedProducts)}
    </div>
  );
};
