import React from 'react';
import { CheckboxWithLabel } from '@noths/polaris-client-ribbons-design-system';
import { visuallyHidden } from '@noths/polaris-client-styles';

import * as styles from './styles';

type CheckboxData = {
  checked?: boolean;
  labelText: string;
  secondaryLabelText?: string;
  value: string;
};

export interface CheckboxGroupProps {
  accessibleLabel: string;
  accessibleLabelElementId?: string;
  accessibleLabelElementType?: keyof JSX.IntrinsicElements;
  checkboxData: CheckboxData[];
  hideLabel?: boolean;
  name: string;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * The accessibleLabelElementType prop is a string which represents the type of HTML element to use for the label e.g. 'h1'.
 * The accessibleLabelElementId prop contains a string to customise the id attribute for the label element containing the accessible label.
 */
export const CheckboxGroup = ({
  name,
  accessibleLabel,
  accessibleLabelElementType: AccessibleLabelElementTag = 'p',
  accessibleLabelElementId = `${name}-label`,
  onChange,
  checkboxData,
  hideLabel,
}: CheckboxGroupProps) => {
  return (
    <div aria-labelledby={accessibleLabelElementId} role="group">
      <span css={hideLabel ? visuallyHidden : undefined} id={accessibleLabelElementId}>
        <AccessibleLabelElementTag>{accessibleLabel}</AccessibleLabelElementTag>
      </span>
      {checkboxData.map(({ checked, labelText, secondaryLabelText, value: checkboxValue }) => (
        <div css={styles.checkboxWrapperContainer} data-testid={checkboxValue} key={checkboxValue}>
          <CheckboxWithLabel
            /*
            Fixes https://notonthehighstreet.atlassian.net/browse/FFA-2190.
            Transform scale works by moving the element outside its actual layer hierarchy
            before manipulating it, which is the likely culprit behind the checkmark icon
            not being shown following a reflow/repaint in complex layout changes.
          */
            checked={checked}
            css={styles.checkboxWrapper}
            disableCssTransforms
            key={checkboxValue}
            label={labelText}
            name={name}
            onChange={onChange}
            value={checkboxValue}
          />
          {secondaryLabelText && <span css={styles.secondaryLabel}>{secondaryLabelText}</span>}
        </div>
      ))}
    </div>
  );
};
