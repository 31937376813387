import { fontSize, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import { mediaQuery, pxToRem, stack } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing, text } = theme;

export const radioButtonWrapper = css`
  display: flex;
  margin: ${stack(spacing.lg)};
  cursor: pointer;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      margin: ${stack(spacing.sm)};
    `,
  )}
`;

export const radioButtonLabelText = css`
  flex-grow: 1;
  margin: ${pxToRem(spacing.base)} 0 0 ${pxToRem(spacing.xs)};
  ${fontSize(text.BODY.MD)};
`;
